import React from 'react';
import { ESize } from 'review/types';
import ReviewProductRating from './elements/ReviewProductRating';
import { scrollPageToAndFireEvent } from 'shared/helpers';

type OwnProps = {
	averageRating: number;
	totalRatingsCount: number;
	labelSingular?: string;
	labelPlural?: string;
	large?: boolean;
	center?: boolean;
	link?: boolean;
};

const ProductReviews: React.FC<OwnProps> = (props) => {
	const {
		averageRating,
		totalRatingsCount,
		labelSingular,
		labelPlural,
		large,
		center,
		link,
	} = props;

	const total = totalRatingsCount ? totalRatingsCount : 0;

	if (total <= 0) {
		return null;
	}

	let reviewElement;

	const onClick = (e: any) => {
		e.preventDefault();

		scrollPageToAndFireEvent('#reviewsTab', 'openTab');
	};

	let label = total === 1 ? labelSingular : labelPlural;
	let cssClass = `rating-stars-wrapper ${center === true ? 'jc-center' : ''} ${
		link === true ? 'clickable' : ''
	}`;

	let starsElement = (
		<ReviewProductRating
			name='rating-avg'
			size={ESize.large}
			disabled
			ratingValue={averageRating}
			precision={0.1}
		/>
	);

	let ratingElement = (
		<i className='review-total-label'>
			{total} {label && ' ' + label}
		</i>
	);

	if (center === true) {
		let cssClassStars = `${cssClass} mb`;
		let cssClassRating = `${cssClass} mb-2x`;
		reviewElement = (
			<>
				<div className={cssClassStars} onClick={onClick}>
					{starsElement}
				</div>
				<div className={cssClassRating} onClick={onClick}>
					{ratingElement}
				</div>
			</>
		);
	} else {
		let cssClassStars = `${cssClass} mb-3x`;
		reviewElement = (
			<>
				<div className={cssClassStars} onClick={onClick}>
					{starsElement}
					{ratingElement}
				</div>
			</>
		);
	}

	return <>{reviewElement}</>;
};

export default ProductReviews;
