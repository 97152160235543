import { combineReducers } from 'redux';
import { IProductDetailReviews } from './types';
import produce from 'immer';

const initialReviews: IProductDetailReviews = {
	averageRating: 0,
	totalRatingsCount: 0,
};

const reduceReviews = produce((draft: IProductDetailReviews) => {
	if (!draft) {
		return initialReviews;
	}

	const productDetailReviews: IProductDetailReviews = {
		averageRating: draft.averageRating,
		totalRatingsCount: draft.totalRatingsCount,
	};

	return productDetailReviews;
});

const localizations = (state = {}): object => {
	return state;
};

const extensions = (state = {}): object => {
	return state;
};

const synchronizerToken = (state = {}): object => {
	return state;
};

//reducers that operate on the different states should be 'combined'
const rootReducer = combineReducers({
	reviews: reduceReviews,
	localizations,
	extensions,
	synchronizerToken,
});

export default rootReducer;
