import ProductReviews from '../../shared/components/ProductReviews';
import React, { FC } from 'react';
import { IProductDetailReviews } from './types';
import createConnect from './connect';

interface OwnProps {}

interface StateProps {
	reviews: IProductDetailReviews;
	localizations: object;
}

interface DispatchProps {}

type IProps = OwnProps & StateProps & DispatchProps;

const SaleBlockReviewsApp: FC<IProps> = (props) => {
	const { localizations, reviews } = props;
	const { totalRatingsCount, averageRating } = reviews;

	return (
		<ProductReviews
			totalRatingsCount={totalRatingsCount}
			averageRating={averageRating}
			labelSingular={localizations['product.reviews.title.text']}
			labelPlural={localizations['product.reviews.title.plural.text']}
			center
		/>
	);
};

export default createConnect<StateProps, DispatchProps, OwnProps>()(SaleBlockReviewsApp);
