export interface IReviewProduct {
	sku: string;
	reviewSKU: string;
	name: string;
	link: string;
	images: IReviewProductImages[];
}

export interface IReviewProductImages {
	name: string;
	altText: string;
	imageLink: string;
}

export interface IProductReviews {
	totalRatingsCount: number;
	averageRating: number;
	reviews: IProductReview[];
	reviewSKU: string;
	submitState?: IDispatchState;
}

export interface IProductReview {
	firstName: string;
	lastName: string;
	authorName: string;
	authorEmail: string;
	title: string;
	content: string;
	ratingValue: number | undefined | null;
	creationDate: Date;
}

export interface IFormValues {
	title: string;
	email: string;
	author: string;
	content: string;
	ratingValue?: number | undefined | null;
}
export interface IFormErrors {
	title: boolean;
	email: boolean;
	author: boolean;
	content: boolean;
	ratingValue: boolean | null | undefined;
}

export interface IDispatchState {
	isFetching: boolean;
	showError: boolean;
	success: boolean;
}

export enum ProductReviewActions {
	REQUEST_ADDPRODUCTREVIEW = 'REQUEST_ADDPRODUCTREVIEW',
	RECEIVE_ADDPRODUCTREVIEW = 'RECEIVE_ADDPRODUCTREVIEW',
}

interface RequestAddProductReviewAction {
	type: typeof ProductReviewActions.REQUEST_ADDPRODUCTREVIEW;
	sku: string;
	productReview: IProductReview;
}

interface ReceiveAddProductReviewAction {
	type: typeof ProductReviewActions.RECEIVE_ADDPRODUCTREVIEW;
	data: any;
	sku: string;
	productReview: IProductReview;
}

export enum ESize {
	large = 'large',
	xlarge = 'xlarge',
}

export type ProductReviewActionsTypes =
	| RequestAddProductReviewAction
	| ReceiveAddProductReviewAction;
