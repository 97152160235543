import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { logger } from 'redux-logger';
import rootReducer from './reducers';

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

export default function configureStore(preloadedState) {
	let middleware = [thunkMiddleware];
	if (process.env.NODE_ENV !== 'production') {
		middleware = [...middleware, logger];
	}

	return createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware)));
}
