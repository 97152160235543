import { connect } from 'react-redux';

function createConnect<StateProps, DispatchProps, OwnProps>() {
	const mapStateToProps = (state: any): any => {
		return {
			localizations: state.localizations,
			extensions: state.extensions,
			synchronizerToken: state.synchronizerToken,
			reviews: state.reviews,
		};
	};

	const connectContext = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps);
	return connectContext;
}

export default createConnect;
