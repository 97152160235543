import React from 'react';
import Rating from '@material-ui/lab/Rating';
import { ESize } from 'review/types';
interface OwnProps {
	disabled?: boolean;
	size?: ESize;
	precision?: number;
	name: string;
	ratingValue?: number | null;
	onChange?: any;
}
/**
 * @param {boolean} large Make the stars large
 * @param {string} name Unique name for the Rating element
 * @param {boolean} disabled Disable editing the stars
 * @param {function} onChange
 * @param {number} precision Example: 1 or 0.5
 */
const ReviewProductRating: React.FC<OwnProps> = (props) => {
	const { ratingValue, disabled, name, size, precision, onChange } = props;

	return (
		<>
			<Rating
				className={`rating-stars ${size ? size : ''}`}
				name={name}
				precision={precision ? precision : 1}
				disabled={disabled}
				value={ratingValue && ratingValue}
				onChange={onChange}
			/>
		</>
	);
};

export default ReviewProductRating;
