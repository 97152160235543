import React from 'react';
import { render } from 'react-dom';
import configureStore from './configureStore';
import Root from './root';
import { createGeneralComponents } from '../../shared/general';

function run(element, initialData, localizations, extensions, synchronizerToken) {
	let reviews = {};

	if (initialData) {
		if (initialData.productReviews) {
			reviews = initialData.productReviews;
		}
	}

	const initialState: any = {
		reviews: reviews,
		localizations: localizations,
		extensions: extensions,
		synchronizerToken: synchronizerToken,
	};

	const store = configureStore(initialState);

	createGeneralComponents(store);
	window['__MASTER_REACT_APP__'] = element.id;
	render(<Root store={store} />, element);
}

export default { run: run };
