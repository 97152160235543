export const scrollPageTo = (selector: string) => {
	const element = document.querySelector(selector) as HTMLElement;
	scrollTo(element);
};

export const scrollPageToAndFireEvent = (selector: string, eventName: string) => {
	const element = document.querySelector(selector) as HTMLElement;

	scrollTo(element);
	element.dispatchEvent(new CustomEvent(eventName));
};

const scrollTo = (element: HTMLElement) => {
	const topPos = element.getBoundingClientRect().top;
	window.scrollTo({ top: topPos - 100, behavior: 'smooth' });
};