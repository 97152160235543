import React, { Component } from 'react';
import { Provider } from 'react-redux';
import SaleBlockReviewsApp from './SaleBlockReviewsApp';

interface IProps {
	store: any;
}

class Root extends Component<IProps> {
	render = () => {
		const { store } = this.props;

		return (
			<Provider store={store}>
				<SaleBlockReviewsApp />
			</Provider>
		);
	};
}

export default Root;
